<template>
  <section class="relative">
    <div class="bg-white pt-24 md:pt-40 pb-20 md:pb-28 xl:pb-32">
      <div class="max-w-4xl mx-auto px-4 text-center flex flex-col justify-center items-center mb-10 lg:mb-12">
          <i18n-t keypath="hero-tagline" tag="h1" class="text-heros md:text-herom font-bold font-poppins mb-5 px-8 sm:px-0">
            <template #highlight>
              <span class="gradient-header">{{ $t('hero-tagline.highlight') }}</span>
            </template>
          </i18n-t>
          <p class="text-p1s md:text-p1m mb-5">{{ $t('hero-claim') }}</p>
          <a :href="localePath('/signup')" class="text-white flex items-center bg-brand-500 py-3 pl-5 pr-3 rounded-m font-bold gap-1">{{$t('hero-cta')}} <SvgChevronRight /></a>
      </div>
      <div class="max-w-5xl mx-auto relative px-4 xl:px-0 hero-shadow">
        <picture class="w-full h-auto">
          <source media="(min-width: 1024px)" srcset="~/assets/img/home/hero.webp">
          <source media="(max-width: 1023px)" srcset="~/assets/img/home/hero-small.webp">
          <img src="~/assets/img/home/hero.webp" width="3600" height="2025" :alt="$t('hero-image.alt')">
        </picture>
        <div class="absolute right-12 top-4 w-30 sm:right-12 sm:top-8 sm:w-32 md:right-20 md:top-24 md:w-60 lg:top-32 lg:w-72 xl:-right-8 xl:top-28 xl:w-80 h-auto">
          <video ref="video" class="absolute w-full h-auto min-w-full rounded-lg video-shadow"
                 loading="lazy" autoplay muted playsinline loop
                 src="https://store.soundmadeseen.com/home-videos/home-small.mp4"></video>
          <ControlsMuter class="absolute top-4 right-4" :muted="true" @mute="handleMute"/>
        </div>

      </div>
    </div>

  </section>
</template>

<script setup>
import hls from "hls.js";

const config = useRuntimeConfig();
const video = ref(null);

const videoLoaded = ref(false);

onMounted(() => {


});

const handleMute = (muted) => {
  video.value.muted = muted;
}


</script>

<style scoped>
  .hero-shadow {
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);
  }
  .video-shadow {
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.10);
  }
</style>
